import React from "react"
import { Link } from "gatsby"

import HeaderServices from "../../components/header_services"

export default function Offerings() {
  const heroTitle = "Offerings"

  return (
    <HeaderServices heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <ul>
        <li>
          <Link to="/services/offerings/comprehensive-wealth-management">
            Comprehensive Wealth Management
          </Link>
        </li>
        <li>
          <Link to="/services/offerings/direct-equities-advisory">Direct Equities Advisory</Link>
        </li>
        <li>
          <Link to="/services/offerings/mutual-funds-advisory">Mutual Funds Advisory</Link>
        </li>
        <li>
          <Link to="/services/offerings/second-look">Second Look</Link>
        </li>
      </ul>
    </HeaderServices>
  )
}
